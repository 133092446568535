<template>
  <div>
    <el-card class="box-card">
      <div class="infoBox">
        <div class="title">订单编号:</div>
        {{ details.code }}
      </div>
      <div class="infoBox">
        <div class="title">订单状态:</div>
        {{
          details.status == 0
            ? "待支付"
            : details.status == 10
            ? "待发货"
            : details.status == 11
            ? "部分发货"
            : details.status == 20
            ? "待收货"
            : details.status == 30
            ? "待评价"
            : details.status == 40
            ? "已完成"
            : "已关闭"
        }}
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">订单信息</div>
      <div class="infoBox">
        <div class="title">收货人姓名:</div>
        {{ details.shippingName }}
      </div>
      <div class="infoBox">
        <div class="title">收货人电话:</div>
        {{ details.shippingMobile }}
      </div>
      <div class="infoBox">
        <div class="title">收货人地址:</div>
        {{
          details.shippingProvince +
          details.shippingCity +
          details.shippingDistrict +
          details.shippingAddress
        }}
      </div>
      <div class="infoBox">
        <div class="title">订单金额:</div>
        {{ details.orderAmount }}
      </div>
      <div class="infoBox">
        <div class="title">实付金额(元）:</div>
        {{ details.payAmount }}
      </div>
      <div class="infoBox">
        <div class="title">积分抵扣(元):</div>
        {{ details.scoreAmount }}
      </div>
      <div class="infoBox">
        <div class="title">手机号:</div>
        {{ details.shippingMobile }}
      </div>
      <div class="infoBox" v-if="details.payAt">
        <div class="title">支付时间:</div>
        {{ details.payAt }}
      </div>
      <div class="infoBox">
        <div class="title">下单时间:</div>
        {{ details.orderTime }}
      </div>
      <div class="infoBox" v-if="details.closedReason">
        <div class="title">关闭原因:</div>
        {{ details.closedReason }}
      </div>
      <div class="infoBox" v-if="details.closedAt">
        <div class="title">关闭时间:</div>
        {{ details.closedReason }}
      </div>
    </el-card>

    <el-card class="box-card" v-if="type !='supplierOrders'&&type !='supplier'">
      <div slot="header" class="clearfix">子订单</div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        class="table"
        row-key="id"
      >
        <el-table-column prop="" width="50" align="center" label="">
        </el-table-column>
        <el-table-column label="订单号" prop="code" min-width="170" />
        <el-table-column label="订单金额" prop="orderAmount" min-width="170" />
        <el-table-column label="下单时间" prop="orderTime" min-width="150" />
        <el-table-column label="订单状态" min-width="130">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.status == 0
                  ? "待支付"
                  : scope.row.status == 10
                  ? "待发货"
                  : scope.row.status == 11
                  ? "部分发货"
                  : scope.row.status == 20
                  ? "待收货"
                  : scope.row.status == 30
                  ? "待评价"
                  : scope.row.status == 40
                  ? "已完成"
                  : "已关闭"
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="onOrderDetails(scope.row)"
              >订单商品详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-card class="box-card" v-else>
      <div slot="header" class="clearfix">商品详情</div>
     <GoodsCardFinance :shopList="details.orderItems" />
    </el-card>

    <el-dialog title="商品详情" :visible.sync="dialogVisible" width="60%">
      <GoodsCardFinance :shopList="shopList" />
      <!-- <el-table
        v-loading="listLoading"
        :data="shopList"
        size="small"
        class="table"
        row-key="id"
      >
        <el-table-column label="商品图" min-width="170">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.goodsImg"
                :preview-src-list="[scope.row.goodsImg]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" min-width="170" />
        <el-table-column label="商品SKU图片" min-width="170">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.goodsSkuImg"
                :preview-src-list="[scope.row.goodsSkuImg]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="商品SKU编码"
          prop="goodsSkuCode"
          min-width="170"
        />
        <el-table-column
          label="商品SKU名称"
          prop="goodsSkuName"
          min-width="170"
        />
        <el-table-column label="原价" prop="originalPrice" min-width="170" />
        <el-table-column label="商品数量" prop="quantity" min-width="170" />
      </el-table> -->
    </el-dialog>
  </div>
</template>
<script>
import { supplierOrderDetail,storeOrderDetail,OrderDetailByStore,OrderDetailBySupplier } from "@/api/order/order";
import GoodsCardFinance from './components/goodsCardFinance.vue'
export default {
  components:{
    GoodsCardFinance
  },
  data() {
    return {
      id: "",
      listLoading: false,
      details: {},
      tableData: {
        data: [],
      },
      dialogVisible: false,
      type: this.$route.query.type,
      shopList: [],
    };
  },
  created() {
    console.log('123121')
    this.id = this.$route.query.id;
    this.getDetails();
  }, 
  methods: {
    // 列表
    getDetails() {
      console.log('this.type',this.type)
      this.listLoading = true;
      //门店财务订单详情
      if (this.type == "store") {
        console.log('-0-----')
        storeOrderDetail({ orderId: this.id })
          .then((res) => {
            this.details = res.data;
            this.tableData.data = res.data.ordersSubs;
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
          });
      }
      //供应商财务订单详情
      if (this.type === "supplier") {
        supplierOrderDetail({ orderId: this.id })
          .then((res) => {
            this.details = res.data;
            this.tableData.data = res.data.ordersSubs;
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
          });
      }
      //门店财务订单详情
      if (this.type == "storeOrders") {
        OrderDetailByStore({ orderId: this.id })
          .then((res) => {
            this.details = res.data;
            this.tableData.data = res.data.ordersSubs;
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
          });
      }
      //供应商订单详情
      if (this.type == "supplierOrders") {
        OrderDetailBySupplier({ orderId: this.id })
          .then((res) => {
            this.details = res.data;
            this.tableData.data = res.data.ordersSubs;
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
          });
      }
    },
    onOrderDetails(row) {
      this.dialogVisible = true;
      this.shopList = row.orderItems;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  margin-bottom: 20px;
}
.infoBox {
  display: flex;
  margin: 12px 0px;
}
.title {
  width: 120px;
}
</style>
