<template>
  <div>
    <el-table
        v-loading="listLoading"
        :data="shopList"
        size="small"
        class="table"
        row-key="id"
      >
      <el-table-column
          type="index"
          width="80"
          label="序号"
        />
        <el-table-column label="商品图" min-width="170">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.goodsImg"
                :preview-src-list="[scope.row.goodsImg]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" min-width="170" />
        <el-table-column label="商品SKU图片" min-width="170">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.goodsSkuImg"
                :preview-src-list="[scope.row.goodsSkuImg]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="商品SKU编码"
          prop="goodsSkuCode"
          min-width="170"
        />
        <el-table-column
          label="商品SKU名称"
          prop="goodsSkuName"
          min-width="170"
        />
        <el-table-column label="原价" prop="salePrice" min-width="170" />
        <el-table-column label="商品数量" prop="quantity" min-width="170" />
      </el-table>
  </div>
</template>
<script>
export default {
  name: "goodsCardFinance",
  props:{
    shopList: {
      type: Array,
      default: ""
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
